const contactItems = [
    {
        value: "+91 89274 70006",
        icon: "fa-solid fa-phone"
    },
    {
        value: <span>Dinanagar Bypass<br/>Haripur, Punjab</span>,
        icon: "fa-solid fa-location-dot"
    },
    {
        value: "info@raaviresort.in",
        icon: "fa-regular fa-at"
    },
];

export default contactItems;